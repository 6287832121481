<template>
  <div class="container">
    <SimpleHeader />
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="teamw">
          <div class="fans">
            <div class="fans-body">
              <div class="attention">
                  <FansItem :item="info" v-if="info?.statistics?.length"></FansItem>
                </div>
            </div>
          </div>
           <van-list
              v-model:loading="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="getData"
              offset="100"
              loading-text="加载中..."
              :immediate-check="false"
            >
              <div class="attention">
                <FansItem :item="item"  v-for="(item,index) in list" :key="index"></FansItem>
                <Empty :image="true" :show="!loading&&list.length==0"></Empty>
              </div>
            </van-list>
        </div>
      </van-pull-refresh>
  </div>
</template>
<script>
import { reactive, onMounted, inject, toRefs,watch  } from 'vue'
import { useRoute } from 'vue-router'
import useTime from '@/utils/time'
import { List, PullRefresh, Tab, Tabs } from 'vant'
import SimpleHeader from '@/components/SimpleHeader'
import utils from '@/utils'
import Filter from '@/components/Filter'
import FilterCell from '@/components/FilterCell'
import FilterItem from '@/components/FilterItem'
import FansItem from './components/FansItem.vue'
import {getFansData,getFansStatistics } from '@/api/fans'
import to from 'await-to-js';

export default {
  name: 'FANSDETAIL',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader,
    Filter,
    FilterCell,
    FilterItem,
    FansItem
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { query } = useRoute()
    const {
      time
    } = useTime()
    let state = reactive({
      userId:0,
      search:'',
      timeTypeList:[],
      hasMore:true,
      page:1,
      size:20,
      list: [],
      error: false,
      loading: false,
      finished: false,
      finishedText:'',
      refreshing: false,
      filterVisible:false,
      userType:1,
      level:0,
      sortType:0,
      timeType:'',
      info:{}
    })
    const getStatistics = async () => {
      let params = {
        fromUid:state.userId,
        level:state.level,
        sort:state.sortType,
        start: '',
        end: '',
        search:state.search,
        page:state.page,
        userType:state.userType,
      }
      let [error,result] = await to(getFansStatistics(params))
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        state.info = res.info
      }
    }
    const getData = async () => {
      if (!state.loading) {
        state.loading = true;
      }
      if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
      }
      let params = {
        fromUid:state.userId,
        level:state.level,
        sort:state.sortType,
        start: '',
        end: '',
        search:state.search,
        page:state.page,
        userType:state.userType,
        type:''
      }
      let [error,result] = await to(getFansData(params))
      _appLoadFanish()
      state.loading = false;
      if(error) {
        state.finished = true;
        state.finishedText = ''
        return
      };
      let {code, res} = result;
      if (code == 0) {
        state.list = state.page === 1 ? res.list : state.list.concat(res.list)
        state.finishedText = state.page === 1 && state.list.length == 0 ? '' : `当前共${state.list.length}条数据，已全部加载完`
        state.hasMore = res.hasMore
        if (!state.hasMore) {
          state.finished = true;
        } else {
          state.page++
        }
      }

    };
    const onRefresh = () => {
      state.loading = true;
      initData();
      getStatistics()
      getData();
    };
     function initData() {
      state.page = 1
      state.list = []
      state.finished = false
    }

    onMounted(async () => {
      if (query && query.hasOwnProperty('id')) {
        state.userId = query.id
      }
      getStatistics()
      getData()
    })


    return {
      ...toRefs(state),
      onRefresh,
      getData
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #F5F6F7;
  min-height: 100%;
  padding-bottom: 50px;
  padding-bottom: calc(50px + constant(safe-area-inset-bottom,50px))!important;
  padding-bottom: calc(50px + env(safe-area-inset-bottom,50px))!important;
  .fans{
    margin-bottom: 8px;
    &-head{
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      padding: 0 15px;
      // background: #fff;
    }
    &-body{
      background: #fff;

      // box-sizing: border-box;
      padding: 0 15px 15px ;
    }
    &-infobox{
      background: #DABA88;
      color: #000;
      .borderRadius(6px);
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 33.33%);
      grid-template-rows: repeat(2, 60px);
    }
    &-infotxt{
      background: #DABA88;
      color: #000;
      .borderRadius(6px);
      height: 180px;
      line-height: 180px;
      text-align: center;
    }
    &-info{
      text-align: center;
      &-num{
        margin-top: 15px;
      }
      &-txt{
        margin-top: 4px;
      }
    }
  }
}
</style>